import LayoutProductPage from '../../components/layouts/ProductPage';
import * as style from '../../styles/pages/productIndex.module.scss';
import * as gstyle from '../../styles/styles.module.scss';
import Footer from '../../components/Footer';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { commonlocalesData } from '../../data/commonlocales.data';

export default function index() {
  const locales = commonlocalesData();
  return (
    <section>
      <Helmet title="TALXIS Služby"> </Helmet>
      <LayoutProductPage
        backLink="/"
        header={'Služby'}
        copy={
          'Představte si informační systém, který si poskládáte podle vlastních představ a upravíte na míru přímo Vašim potřebám.'
        }
      >
        <section className={style.productList}>
          <section key={'designSprint'} className={classnames(style.product, style.blue)}>
            <div className={classnames(gstyle.center, style.center)}>
              <div
                className={style.image}
                style={{
                  backgroundImage: `url("https://wordpress.talxis.com/wp-content/uploads/2023/01/contracts.webp")`,
                }}
              ></div>
              <div className={style.copy}>
                <h1>Design sprint</h1>
                <p>
                  Metoda, která zkracuje dobu vývoje a zajištuje vyšší spokojenost s dodaným
                  řešením.
                </p>
                <p>
                  Vývoj softwarových řešení má často zpoždění a vyžaduje dodatečné peníze a úpravy.
                  Bez jasné definice požadavků od samého začátku projektu však tráví vývojové týmy
                  příliš mnoho času řešením neoptimálně specifikovaných problémů a předěláváním již
                  hotových řešení. Jak bychom mohli zjednodušit proces definice požadavků a urychlit
                  vývoj?
                </p>
              </div>
              <div className={style.button}>
                <Link to="/services/designsprint">{locales.zobrazitvice}</Link>
              </div>
            </div>
          </section>
          <section key={'operationalSupport'} className={classnames(style.product, style.orange)}>
            <div className={classnames(gstyle.center, style.center)}>
              <div
                className={style.image}
                style={{
                  backgroundImage: `url("https://wordpress.talxis.com/wp-content/uploads/2022/04/telefonie.png")`,
                }}
              ></div>
              <div className={style.copy}>
                <h1>Provozní podpora</h1>
                <p>Implementace úprav na základě vašich požadavků a zpětné vazby uživatelů</p>
                <p>
                  Po úvodním vývoji a akceptaci přechází vámi vybraný systém do fáze údržby a
                  dalšího rozvoje. Je důležité očekávat, že v praxi se může systém na základě zpětné
                  vazby uživatelů adaptovat a odchýlit od původních specifikací. Pro zajištění
                  maximální spokojenosti uživatelů je tedy nutné poskytovat kvalitní a lehce
                  dostupnou podporu s rychlými reakcemi na nahlášené incidenty a žádosti o změnu.
                </p>
              </div>
              <div className={style.button}>
                <Link to="/services/operationalsupport">{locales.zobrazitvice}</Link>
              </div>
            </div>
          </section>
        </section>
      </LayoutProductPage>
      <section className={style.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}
